import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../stylesheets/contact_us_form'
import { TextField, withStyles, CircularProgress } from '@material-ui/core'
import { Close, Done } from '@material-ui/icons'
import * as Actions from '../../store/actions/contact_us';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Facebook, LinkedIn, Instagram } from '../icons';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#42bc8e',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#42bc8e',
    },
    '& .MuiTextField-root': {
      width: '25ch',
    },
  },
})(TextField);

const ContactUs = () => {
  const contact_us = useSelector(state => state.contact_us)
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("is required"),
    email: Yup.string().email("is invalid").required("is required"),
    phone: Yup.string(),
    company: Yup.string(),
    message: Yup.string().required("is required"),
  })

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  }

  const renderLocationMark = () => {
    return (
      <svg viewBox="0 0 20 32" xmlns="http://www.w3.org/2000/svg"><g fill="#42bc8e" fillRule="evenodd"><path d="m9.6 32c-.245 0-.477-.112-.629-.306-.091-.117-2.269-2.902-4.475-7.02-1.299-2.421-2.336-4.798-3.08-7.07-.939-2.859-1.414-5.555-1.414-8.01 0-5.293 4.307-9.6 9.6-9.6 5.293 0 9.6 4.307 9.6 9.6 0 2.458-.477 5.152-1.414 8.01-.744 2.267-1.781 4.645-3.08 7.07-2.208 4.114-4.384 6.899-4.475 7.02-.152.194-.384.306-.629.306h-.003m0-30.4c-4.411 0-8 3.589-8 8 0 5.206 2.341 10.662 4.306 14.322 1.443 2.69 2.894 4.822 3.694 5.936.803-1.118 2.264-3.264 3.709-5.962 1.957-3.653 4.291-9.099 4.291-14.296 0-4.411-3.589-8-8-8"/><path d="m9.6 14.4c-2.646 0-4.8-2.154-4.8-4.8 0-2.646 2.154-4.8 4.8-4.8 2.646 0 4.8 2.154 4.8 4.8 0 2.646-2.154 4.8-4.8 4.8m0-8c-1.765 0-3.2 1.435-3.2 3.2 0 1.765 1.435 3.2 3.2 3.2 1.765 0 3.2-1.435 3.2-3.2 0-1.765-1.435-3.2-3.2-3.2"/></g></svg>
    )
  }

  const renderInternet = () => {
    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="#42bc8e" fillRule="evenodd"><path d="m24.452 27.419c.001 0 .002 0 .003-.001h-.003m.521-.14c-.186 0-.376.043-.518.14-2.365 1.754-5.284 2.804-8.454 2.804-7.854 0-14.222-6.367-14.222-14.222 0-7.855 6.368-14.222 14.222-14.222 7.844 0 14.202 6.35 14.22 14.189l1.778-.012c-.025-8.815-7.177-15.955-15.998-15.955-8.836 0-16 7.164-16 16 0 8.836 7.164 16 16 16 3.474 0 6.672-1.128 9.293-3.01.332-.13.568-.447.568-.824 0-.491-.398-.888-.889-.888m5.25-11.278c0 2.455-1.989 4.444-4.444 4.444-2.455 0-4.444-1.989-4.444-4.444v-4.444c0-.492-.397-.889-.889-.889-.492 0-.889.397-.889.889v.496c-.946-.852-2.184-1.385-3.556-1.385-2.946 0-5.333 2.388-5.333 5.333 0 2.945 2.388 5.333 5.333 5.333 1.816 0 3.415-.911 4.378-2.297 1.067 1.892 3.072 3.186 5.4 3.186 3.436 0 6.222-2.786 6.222-6.222h-1.778m-17.778 0c0-1.964 1.592-3.556 3.556-3.556 1.964 0 3.556 1.592 3.556 3.556 0 1.964-1.592 3.556-3.556 3.556-1.964 0-3.556-1.592-3.556-3.556" fill="#42bc8e"/></g></svg>
    )
  }

  const onSubmit = () => {
    if (!contact_us.submitted) dispatch(Actions.requestSubmitContact(contact_us))
  }

  const onClick = (errors, touched) => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0) dispatch(Actions.handleButtonState('error'))
    setTimeout(() => {
      dispatch(Actions.handleButtonState(''))
    }, 3000);
  }

  const classes = useStyles()

  return (
    <div className="section section-landing-5" >
      <div className="contact-container" id="contact-container">
        <div className="contact-form">
          <h2 className="contact-form-title">Let’s work together</h2>
          <Formik initialValues={initialValues} validationSchema={validationSchema} className={classes.root} onSubmit={onSubmit} id="contact-us">
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <Field name="name">
                  {({meta}) => (
                    <div className="contact-form-input-group">
                      <span className="input input--hoshi">
                        <CssTextField
                          id="custom-css-standard-input"
                          label="Name"
                          className={classes.input}
                          onChange={(e) => {
                            dispatch(Actions.handleChange(e))
                            setFieldValue("name", e.target.value)
                          }}
                          onBlur={() => setFieldTouched("name", true)}
                          name="name"
                          value={contact_us.name}
                          error={!!meta.error && !!meta.touched}
                        />
                      </span>
                      <div className="contact-form-input-error">
                        {!!meta.error && !!meta.touched ? meta.error : ''}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="email">
                  {({meta}) => (
                    <div className="contact-form-input-group">
                      <span className="input input--hoshi">
                        <CssTextField
                          id="custom-css-standard-input"
                          label="Email"
                          className={classes.input}
                          onChange={(e) => {
                            dispatch(Actions.handleChange(e))
                            setFieldValue("email", e.target.value)
                          }}
                          onBlur={() => setFieldTouched("email", true)}
                          name="email"
                          value={contact_us.email}
                          error={!!meta.error && !!meta.touched}
                        />
                      </span>
                      <div className="contact-form-input-error">
                        {!!meta.error && !!meta.touched ? meta.error : ''}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="phone">
                  {() => (
                    <div className="contact-form-input-group">
                      <span className="input input--hoshi">
                        <CssTextField
                          id="custom-css-standard-input"
                          label="Phone"
                          className={classes.input}
                          onChange={(e) => {
                            dispatch(Actions.handleChange(e))
                            setFieldValue("phone", e.target.value)
                          }}
                          name="phone"
                          value={contact_us.phone}
                        />
                      </span>
                      <div className="contact-form-input-error"></div>
                    </div>
                  )}
                </Field>
                <Field name="company">
                  {() => (
                    <div className="contact-form-input-group">
                      <span className="input input--hoshi">
                        <CssTextField
                          id="custom-css-standard-input"
                          label="Company"
                          className={classes.input}
                          onChange={(e) => {
                            dispatch(Actions.handleChange(e))
                            setFieldValue("company", e.target.value)
                          }}
                          name="company"
                          value={contact_us.company}
                        />
                      </span>
                      <div className="contact-form-input-error"></div>
                    </div>
                  )}
                </Field>
                <Field name="message">
                  {({meta}) => (
                    <div className="form-input-message" >
                      <span className="input input--hoshi" style={{minHeight:'150px'}}>
                        <CssTextField
                          id="standard-multiline-static"
                          label="Message"
                          multiline
                          rows={4}
                          onChange={(e) => {
                            dispatch(Actions.handleChange(e))
                            setFieldValue("message", e.target.value)
                          }}
                          onBlur={() => setFieldTouched("message", true)}
                          name="message"
                          value={contact_us.message}
                          className={classes.textarea}
                          error={!!meta.error && !!meta.touched}
                        />
                        <div className="contact-form-input-error">
                          {!!meta.error && !!meta.touched ? meta.error : ''}
                        </div>
                        <div className="message-length">{contact_us.message.length}/1000</div>
                      </span>
                    </div>
                  )}
                </Field>
                <div className={contact_us.submitted ? "contact-form-success fade-in":"hidden"}>
                  Successfully submitted
                </div>
                <div className={classes.wrapper}>
                  <button type='submit' className={`button-send-contact button-send-contact-${contact_us.buttonState}`} onClick={() => onClick(errors, touched)}>
                    {contact_us.buttonState === "" && ("SEND")}
                    {contact_us.buttonState === "loading" && <CircularProgress size={24} className={classes.circleLoading} /> }
                    {contact_us.buttonState === "error" && (<Close className={classes.closeIcon} />)}
                    {contact_us.buttonState === "success" && (<Done className={classes.doneIcon} />)}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="clearfix"></div>
        </div>
        <div className="contact-info">
          <h2 className="contact-info-title">Contact Info</h2>
          <table className="contact-info-table">
            <tbody>
              <tr>
                <td>
                  <div className="contact-info-table-img" style={{width:'2rem',height:'2rem'}}>{renderInternet()}</div>
                </td>
                <td>
                  <p className="contact-info-table-text">info@pettersonapps.com</p>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className="contact-info-table-img" style={{width:'1.8rem',height:'1.8rem',textAlign:'center'}}><Skype/></div>
                </td>
                <td>
                  <p className="contact-info-table-text">mark.pettersonapps</p>
                </td>
              </tr> */}
              <tr>
                <td>
                  <div className="contact-info-table-img">{renderLocationMark()}</div>
                </td>
                <td>
                  <p className="contact-info-table-text">Uzhgorod, Ukraine</p>
                  <p className="contact-info-table-text">Drohobych, Ukraine</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="contact-info-social">
            <div className="contact-info-social-item">
              <a href="https://www.facebook.com/pettersonapps.company/" target="_blank" rel="noreferrer noopener">
                <Facebook/>
              </a>
            </div>
            <div className="contact-info-social-item">
              <a href="https://www.linkedin.com/company/pettersonapps" target="_blank" rel="noreferrer noopener">
                <LinkedIn/>
              </a>
            </div>
            <div className="contact-info-social-item">
              <a href="https://www.instagram.com/petterson.apps/" target="_blank" rel="noreferrer noopener">
                <Instagram/>
              </a>
            </div>
          </div>
          <h2 className="contact-info-subtitle">Sales Partner in Norway</h2>
          <h2 className="contact-info-studio">STUDIO X AS</h2>
          <table className="contact-info-table">
            <tbody>
              <tr>
                <td>
                  <div className="contact-info-table-img" style={{width:'2rem',height:'2rem'}}>{renderInternet()}</div>
                </td>
                <td>
                  <p className="contact-info-table-text">post@studiox.no</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="contact-info-table-img">{renderLocationMark()}</div>
                </td>
                <td>
                  <p className="contact-info-table-text">c/o Inspiria Science Center, Bjørnstadveien 16, 1712 Grålum</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
